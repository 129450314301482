.ReactModal__Overlay {
    background-color: rgba(66, 66, 66, .5);
    opacity: 0;
    transition: opacity 150ms linear;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Overlay.ReactModal__Overlay--before-close {
    transition-delay: 200ms;
} 

.ReactModal__Content {
    opacity: 0;
    transform:  translate(0, -50px);
    transition: all 250ms ease-out;
} 
.ReactModal__Content--after-open {
    opacity: 1;
    transform:  translate(0, 0px);
}

.ReactModal__Content--before-close {
    opacity: 0;
    transform:  translate(0, -50px);
}